<template>
  <div class="customDiv">
    <section
      v-for="(item, index) in createHouseItemRequestDtos"
      :key="index"
      class="custom-modal__wrapper"
      style="border-width: 3px;padding: 10px"
    >
      <div style="display: flex;justify-content: space-between;">
        <h1 style="font-size: 30px;display:flex;align-items: center;gap:10px;">
          <img src="../../assets/images/houseImage.svg">
          <p
            v-if="!item.editable"
            style="margin:0px"
          >
            {{ item.name }}
          </p>
          <b-form-input
            v-if="item.editable"
            v-model="item.name"
            type="text"
            placeholder="Enter house name"
            @keyup.enter="item.editable = false"
          />
          <span
            style="cursor:pointer;font-size:20px"
            @click="item.editable = true"
          >
            <b-icon-pencil
              v-if="!item.editable"
            />
          </span>

          <b-icon-check
            v-if="item.editable"
            style="cursor:pointer"
            @click="item.editable = false"
          />
        </h1>
        <div style="display: flex;gap: 5px;">
          <button
            class="button"
            type="button"
            @click="addFloor(index)"
          >
            Shto Katin e ri
          </button>
          <button
            class="button"
            type="button"
            @click="removeHouse(index)"
          >
            {{ $t('Remove') }}
          </button>
        </div>
      </div>
      <p
        v-if="item.createFloorRequestDtos.length == 0 && isButtonSubmited "
        style="color: red;padding-top: 5px;
                margin-bottom: 0px;"
      >
        {{ $t('Please select a floor') }}
      </p>
      <hr style="color:gray;margin:7px !important;">
      <div
        v-for="(floor, floorIndex) in item.createFloorRequestDtos"
        :key="floorIndex"
        class="custom-divs1"
      >
        <div style="display: flex;justify-content: space-between;">
          <h1 style="font-size: 30px;display:flex;align-items: center;gap:10px;">
            <img :src="require(`../../assets/images/floor${Math.min(floorIndex + 1, 3)}.svg`)">
            <p
              v-if="!floor.editable"
              style="margin:0px"
            >
              {{ floor.name }}
            </p>
            <b-form-input
              v-if="floor.editable"
              v-model="floor.name"
              type="text"
              placeholder="Enter floor name"
              @keyup.enter="floor.editable = false"
            />
            <span
              style="cursor:pointer;font-size:20px"
              @click="floor.editable = true"
            >
              <b-icon-pencil
                v-if="!floor.editable"
              />
            </span>

            <b-icon-check
              v-if="floor.editable"
              style="cursor:pointer"
              @click="floor.editable = false"
            />
          </h1>
          <div style="display: flex;gap: 5px;">
            <button
              class="button"
              type="button"
              @click="addRoom(index, floorIndex)"
            >
              Shto Dhomen e re
            </button>
            <button
              class="button"
              type="button"
              @click="removeFloor(index, floorIndex)"
            >
              {{ $t('Remove') }}
            </button>
          </div>
        </div>
        <p
          v-if="floor.createRoomRequestDtos.length == 0 && isButtonSubmited "
          style="color: red;padding-top: 5px;
                margin-bottom: 0px;"
        >
          {{ $t('Please select a room') }}
        </p>
        <hr style="color:gray;margin:7px !important;">
        <div
          v-for="(room, roomIndex) in floor.createRoomRequestDtos"
          :key="roomIndex"
          class="custom-divs2"
        >
          <div style="display: flex;justify-content: space-between;">
            <h1 style="font-size: 30px;display:flex;align-items: center;gap:10px;">
              <img src="../../assets/images/room.svg">
              <p
                v-if="!room.editable"
                style="margin:0px"
              >
                {{ room.name }}
              </p>
              <b-form-input
                v-if="room.editable"
                v-model="room.name"
                type="text"
                placeholder="Enter room name"
                @keyup.enter="room.editable = false"
              />
              <span
                style="cursor:pointer;font-size:20px"
                @click="room.editable = true"
              >
                <b-icon-pencil
                  v-if="!room.editable"
                />
              </span>

              <b-icon-check
                v-if="room.editable"
                style="cursor:pointer"
                @click="room.editable = false"
              />
            </h1>
            <div style="display: flex;gap: 5px;">
              <button
                class="button"
                type="button"
                @click="addWindow(index, floorIndex, roomIndex)"
              >
                Shto Objektin e ri
              </button>
              <button
                class="button"
                type="button"
                @click="removeRoom(index, floorIndex, roomIndex)"
              >
                {{ $t('Remove') }}
              </button>
            </div>
          </div>
          <p
            v-if="room.createWindowRequestDtos.length == 0 && isButtonSubmited "
            style="color: red;padding-top: 5px;
                margin-bottom: 0px;"
          >
            {{ $t('Please select a object') }}
          </p>
          <hr style="color:gray;margin:7px !important;">
          <div
            v-for="(window, windowIndex) in room.createWindowRequestDtos"
            :key="windowIndex"
            class="custom-divs3"
          >
            <div style="display: flex;justify-content: space-between;">
              <h1 style="font-size: 30px;display:flex;align-items: center;gap:10px;">
                <img :src="require(`../../assets/images/floor${Math.min(windowIndex + 1, 3)}.svg`)">
                <p
                  v-if="!window.editable"
                  style="margin:0px"
                >
                  {{ window.name }}
                </p>
                <b-form-input
                  v-if="window.editable"
                  v-model="window.name"
                  type="text"
                  placeholder="Enter window name"
                  @keyup.enter="window.editable = false"
                />
                <span
                  style="cursor:pointer;font-size:20px"
                  @click="window.editable = true"
                >
                  <b-icon-pencil
                    v-if="!window.editable"
                  />
                </span>

                <b-icon-check
                  v-if="window.editable"
                  style="cursor:pointer"
                  @click="window.editable = false"
                />
              </h1>
              <div style="display: flex;gap: 5px;">
                <button
                  class="button"
                  type="button"
                  @click="addMaterial(index, floorIndex, roomIndex,windowIndex)"
                >
                  Shto Materiale
                </button>
                <button
                  class="button"
                  type="button"
                  @click="removeWindow(index, floorIndex, roomIndex,windowIndex)"
                >
                  {{ $t('Remove') }}
                </button>
              </div>
            </div>
            <hr style="color:gray;margin:7px !important;">

            <div style="margin-top: 10px;">
              <div style="display:flex;width:100%;margin-bottom:15px;gap:10px">
                <div style="display:flex;width: 97%">
                  <b-form-group
                    style="width:100%"
                    :label="$t('Transport')"
                  >
                    <b-form-checkbox v-model="window.orderOtherService.transport" />
                  </b-form-group>
                  <b-form-group
                    style="width:100%"
                    :label="$t('Montage')"
                  >
                    <b-form-checkbox v-model="window.orderOtherService.montage" />
                  </b-form-group>
                </div>
                <b-form-group
                  :label="$t('HingType')"
                  style="width:50%"
                >
                  <vue-select
                    v-model="window.hingType"
                    :options="hingeTypes"
                    label="name"
                    :reduce="(e) => e.value"
                    :placeholder="$t('Select')"
                    @input="addDecorative(index, floorIndex, roomIndex, windowIndex)"
                  />
                </b-form-group>
                <b-form-group
                  v-if="window.montageValue"
                  :label="$t('MontageStart')"
                  style="width:50%"
                >
                  <date-picker
                    v-model="window.montageStart"
                    type="datetime"
                    :placeholder="$t('SelectD')"
                    style="width:100%"
                  />
                </b-form-group>
                <b-form-group
                  v-if="window.montageValue"
                  :label="$t('MontageEnd')"
                  style="width:50%"
                >
                  <date-picker
                    v-model="window.montageEnd"
                    type="datetime"
                    :placeholder="$t('SelectD')"
                    style="width:100%"
                  />
                </b-form-group>
              </div>

              <div style="display: flex;gap: 25px">
                <b-form-group
                  :label="$t('Width')"
                  style="width:100%"
                >
                  <b-form-input
                    v-model="window.width"
                    type="number"
                  />
                  <p
                    v-if="window.width < 0.0001 && isButtonSubmited "
                    style="color: red;padding-top: 5px;
                    margin-bottom: 0px;"
                  >
                    {{ $t('TheValue') }}
                  </p>
                </b-form-group>
                <b-form-group
                  :label="$t('Height')"
                  style="width:100%"
                >
                  <b-form-input
                    v-model="window.height"
                    type="number"
                  />
                  <p
                    v-if="window.height < 0.0001 && isButtonSubmited "
                    style="color: red;padding-top: 5px;
                    margin-bottom: 0px;"
                  >
                    {{ $t('TheValue') }}
                  </p>
                </b-form-group>
                <b-form-group
                  :label="$t('SewingType')"
                  style="width:100%"
                >
                  <vue-select
                    v-model="window.sewingType"
                    :options="sewingTypes"
                    label="name"
                    :placeholder="$t('Select')"
                  />
                  <p
                    v-if="window.sewingType == null && isButtonSubmited "
                    style="color: red;padding-top: 5px;
                    margin-bottom: 0px;"
                  >
                    {{ $t('Please select a sewing type') }}
                  </p>
                </b-form-group>
              </div>
              <p
                v-if="window.materialVariantInStoreDtos.length == 0 && isButtonSubmited"
                style="color: red;padding-top: 5px;
                margin-top: 15px;"
              >
                {{ $t('AddMaterials') }}
              </p>
              <div
                style="margin-top: 10px;"
                class="modal-card__wrapper"
              >
                <div
                  v-for="(materials, index2) in window.materialVariantInStoreDtos"
                  :key="index2"
                  class="modal-card"
                >
                  <b-form-group
                    id="input-group-3"
                    :label="`${$t('SelectMaterialType')}:`"
                  >
                    <i
                      v-if="window.materialVariantInStoreDtos.length > 1"
                      class="modal-card__remove"
                      @click="removeMaterialItemFromCD(index, floorIndex, roomIndex,windowIndex,index2)"
                    >
                      <b-icon-trash />
                    </i>
                    <div
                      class="flex radio-form"
                      style="justify-content: space-between;"
                    >
                      <div style="display: grid; grid-template-columns: repeat(3, 1fr);">
                        <div
                          :id="`one${index2}`"
                          class="flex aic"
                        >
                          <input
                            v-model="materials.measurementType"
                            type="radio"
                            value="Meter"
                            @click="resetMeter(materials)"
                          >
                          <label :for="`one${index2}`">{{ $t('Meter') }}</label>
                        </div>
                        <div
                          :id="`one${index2}`"
                          class="flex aic"
                        >
                          <input
                            v-model="materials.measurementType"
                            type="radio"
                            value="Package"
                            @click="resetPackage(materials)"
                          >
                          <label :for="`one${index2}`">{{ $t('Package') }}</label>
                        </div>
                        <div
                          :id="`one${index2}`"
                          class="flex aic"
                        >
                          <input
                            v-model="materials.measurementType"
                            type="radio"
                            value="Set"
                            @click="resetSet(materials)"
                          >
                          <label :for="`one${index2}`">{{ $t('Set') }}</label>
                        </div>
                        <div
                          :id="`one${index2}`"
                          class="flex aic"
                        >
                          <input
                            v-model="materials.measurementType"
                            type="radio"
                            value="Pair"
                            @click="resetPair(materials)"
                          >
                          <label :for="`one${index2}`">{{ $t('Pair') }}</label>
                        </div>
                        <div
                          :id="`one${index2}`"
                          class="flex aic"
                        >
                          <input
                            v-model="materials.measurementType"
                            type="radio"
                            value="Verse"
                            @click="resetVerse(materials)"
                          >
                          <label :for="`one${index2}`">{{ $t('Beaded strand') }}</label>
                        </div>
                        <div
                          :id="`two${index2}`"
                          class="flex aic"
                        >
                          <input
                            v-model="materials.measurementType"
                            type="radio"
                            value="Part"
                            @click="resetPart(materials)"
                          >
                          <label :for="`two${index2}`">  {{ $t('Part') }}</label>
                        </div>
                      </div>
                      <td>
                        <img
                          v-if="materials.name != null && materials.name != '' "
                          v-b-tooltip="$t('ClickToViewPhoto')"
                          style="height: 30px; border-radius: 6px;cursor: pointer "
                          src="../../assets/images/imageicon.svg"
                          alt=""
                          @click.stop="getAllImages(materials.name.materialVariantId)"
                        >
                      </td>
                    </div>
                  </b-form-group>
                  <div class="search-toggle">

                    <p>{{ $t('SearchBy') }}</p>
                    <div>
                      <p
                        :class="{ active2: toggleSearch === 'MaterialName' }"
                        @click="showMaterialName(), toggleSearch = 'MaterialName'"
                      >
                        {{ $t('Name') }}
                      </p>
                      <p
                        :class="{ active2: toggleSearch === 'Description' }"
                        @click="showDescription(), toggleSearch = 'Description'"
                      >
                        {{ $t('Description') }}
                      </p>
                    </div>
                  </div>
                  <b-form-group
                    id="input-select-1"
                    label-for="select-1"
                  >
                    <vue-select
                      v-if="materials.measurementType === 'Meter' && toggleSearch === 'Description'"
                      v-model="materials.name"
                      :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVByMeter : getFilterVariantsByCodeLike"
                      :placeholder="$t('Description')"
                      :reduce="(e) => e"
                      label="materialVariantName"
                      @search="onSearchMDesc"
                      @input="currentStock(materials.name.materialVariantId)"
                    >
                      <li
                        slot="list-footer"
                        class="pagination"
                        style="display: flex; width: 100%; justify-content: center;"
                      >
                        <button
                          type="button"
                          :style="
                            pageM == 1
                              ? 'width: 50%; border: none;'
                              : 'width: 50%; border: none; background-color: #242F6E; color: white'
                          "
                          :disabled="pageM == 1"
                          @click="prevMeter"
                        >
                          {{ $t('Prev') }}
                        </button>
                        <button
                          :style="getButtonStyle"
                          type="button"
                          :disabled="selectedMaterialVariant != '' ? pageM == getTotalItemsPages : pageM == getTotalPagesForMVM "
                          @click="nextMeter"
                        >
                          {{ $t('Next') }}
                        </button>
                      </li>
                    </vue-select>
                    <vue-select
                      v-else-if="materials.measurementType === 'Meter' && toggleSearch === 'MaterialName'"
                      v-model="materials.name"
                      :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVByMeter : getMaterialVariantColorNameLike"
                      :placeholder="$t('MaterialVariant')"
                      :reduce="(e) => e"
                      label="materialVariantName"
                      @search="onSearchM"
                      @input="currentStock(materials.name.materialVariantId)"
                    >
                      <li
                        slot="list-footer"
                        class="pagination"
                        style="display: flex; width: 100%; justify-content: center;"
                      >
                        <button
                          type="button"
                          :style="
                            pageM == 1
                              ? 'width: 50%; border: none;'
                              : 'width: 50%; border: none; background-color: #242F6E; color: white'
                          "
                          :disabled="pageM == 1"
                          @click="prevMeter"
                        >
                          {{ $t('Prev') }}
                        </button>
                        <button
                          :style="getButtonStyle"
                          type="button"
                          :disabled="selectedMaterialVariant != '' ? pageM == getTotalItemsPages : pageM == getTotalPagesForMVM "
                          @click="nextMeter"
                        >
                          {{ $t('Next') }}
                        </button>
                      </li>
                    </vue-select>
                    <vue-select
                      v-else-if="materials.measurementType === 'Package' && toggleSearch === 'MaterialName'"
                      v-model="materials.name"
                      :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVByPackage : getMaterialVariantColorNameLike"
                      :placeholder="$t('MaterialVariant')"
                      :reduce="(e) => e"
                      label="materialVariantName"
                      @search="onSearchPack"
                      @input="currentStock(materials.name.materialVariantId)"
                    >
                      <li
                        slot="list-footer"
                        class="pagination"
                        style="display: flex; width: 100%; justify-content: center;"
                      >
                        <button
                          type="button"
                          :style="
                            pageM == 1
                              ? 'width: 50%; border: none;'
                              : 'width: 50%; border: none; background-color: #242F6E; color: white'
                          "
                          :disabled="pagePack == 1"
                          @click="prevPackage"
                        >
                          {{ $t('Prev') }}
                        </button>
                        <button
                          :style="getButtonStyle"
                          type="button"
                          :disabled="selectedMaterialVariantPackage != '' ? pagePack == getTotalItemsPages : pagePack == getTotalPagesForMVM "
                          @click="nextPackage"
                        >
                          {{ $t('Next') }}
                        </button>
                      </li>
                    </vue-select>
                    <vue-select
                      v-else-if="materials.measurementType === 'Package' && toggleSearch === 'Description'"
                      v-model="materials.name"
                      :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVByPackage : getFilterVariantsByCodeLike"
                      :placeholder="$t('Description')"
                      :reduce="(e) => e"
                      label="materialVariantName"
                      @search="onSearchPackDesc"
                      @input="currentStock(materials.name.materialVariantId)"
                    >
                      <li
                        slot="list-footer"
                        class="pagination"
                        style="display: flex; width: 100%; justify-content: center;"
                      >
                        <button
                          type="button"
                          :style="
                            pageM == 1
                              ? 'width: 50%; border: none;'
                              : 'width: 50%; border: none; background-color: #242F6E; color: white'
                          "
                          :disabled="pagePack == 1"
                          @click="prevPackage"
                        >
                          {{ $t('Prev') }}
                        </button>
                        <button
                          :style="getButtonStyle"
                          type="button"
                          :disabled="selectedMaterialVariantPackage != '' ? pagePack == getTotalItemsPages : pagePack == getTotalPagesForMVM "
                          @click="nextPackage"
                        >
                          {{ $t('Next') }}
                        </button>
                      </li>
                    </vue-select>
                    <vue-select
                      v-else-if="materials.measurementType === 'Set' && toggleSearch === 'MaterialName'"
                      v-model="materials.name"
                      :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVBySet : getMaterialVariantColorNameLike"
                      :placeholder="$t('MaterialVariant')"
                      :reduce="(e) => e"
                      label="materialVariantName"
                      @search="onSearchSet"
                      @input="currentStock(materials.name.materialVariantId)"
                    >
                      <li
                        slot="list-footer"
                        class="pagination"
                        style="display: flex; width: 100%; justify-content: center;"
                      >
                        <button
                          type="button"
                          :style="
                            pageM == 1
                              ? 'width: 50%; border: none;'
                              : 'width: 50%; border: none; background-color: #242F6E; color: white'
                          "
                          :disabled="pageSet == 1"
                          @click="prevSet"
                        >
                          {{ $t('Prev') }}
                        </button>
                        <button
                          :style="getButtonStyle"
                          type="button"
                          :disabled="selectedMaterialVariantSet != '' ? pageSet == getTotalItemsPages : pageSet == getTotalPagesForMVM "
                          @click="nextSet"
                        >
                          {{ $t('Next') }}
                        </button>
                      </li>
                    </vue-select>
                    <vue-select
                      v-else-if="materials.measurementType === 'Set' && toggleSearch === 'Description'"
                      v-model="materials.name"
                      :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVBySet : getFilterVariantsByCodeLike"
                      :placeholder="$t('Description')"
                      :reduce="(e) => e"
                      label="materialVariantName"
                      @search="onSearchSetDesc"
                      @input="currentStock(materials.name.materialVariantId)"
                    >
                      <li
                        slot="list-footer"
                        class="pagination"
                        style="display: flex; width: 100%; justify-content: center;"
                      >
                        <button
                          type="button"
                          :style="
                            pageM == 1
                              ? 'width: 50%; border: none;'
                              : 'width: 50%; border: none; background-color: #242F6E; color: white'
                          "
                          :disabled="pageSet == 1"
                          @click="prevSet"
                        >
                          {{ $t('Prev') }}
                        </button>
                        <button
                          :style="getButtonStyle"
                          type="button"
                          :disabled="selectedMaterialVariantSet != '' ? pageSet == getTotalItemsPages : pageSet == getTotalPagesForMVM "
                          @click="nextSet"
                        >
                          {{ $t('Next') }}
                        </button>
                      </li>
                    </vue-select>
                    <vue-select
                      v-else-if="materials.measurementType === 'Pair' && toggleSearch === 'MaterialName'"
                      v-model="materials.name"
                      :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVByPair : getMaterialVariantColorNameLike"
                      :placeholder="$t('MaterialVariant')"
                      :reduce="(e) => e"
                      label="materialVariantName"
                      @search="onSearchPair"
                      @input="currentStock(materials.name.materialVariantId)"
                    >
                      <li
                        slot="list-footer"
                        class="pagination"
                        style="display: flex; width: 100%; justify-content: center;"
                      >
                        <button
                          type="button"
                          :style="
                            pageM == 1
                              ? 'width: 50%; border: none;'
                              : 'width: 50%; border: none; background-color: #242F6E; color: white'
                          "
                          :disabled="pagePair == 1"
                          @click="prevPair"
                        >
                          {{ $t('Prev') }}
                        </button>
                        <button
                          :style="getButtonStyle"
                          type="button"
                          :disabled="selectedMaterialVariantPair != '' ? pagePair == getTotalItemsPages : pagePair == getTotalPagesForMVM "
                          @click="nextPair"
                        >
                          {{ $t('Next') }}
                        </button>
                      </li>
                    </vue-select>
                    <vue-select
                      v-else-if="materials.measurementType === 'Pair' && toggleSearch === 'Description'"
                      v-model="materials.name"
                      :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVByPair : getFilterVariantsByCodeLike"
                      :placeholder="$t('MaterialVariant')"
                      :reduce="(e) => e"
                      label="materialVariantName"
                      @search="onSearchPairDesc"
                      @input="currentStock(materials.name.materialVariantId)"
                    >
                      <li
                        slot="list-footer"
                        class="pagination"
                        style="display: flex; width: 100%; justify-content: center;"
                      >
                        <button
                          type="button"
                          :style="
                            pageM == 1
                              ? 'width: 50%; border: none;'
                              : 'width: 50%; border: none; background-color: #242F6E; color: white'
                          "
                          :disabled="pagePair == 1"
                          @click="prevPair"
                        >
                          {{ $t('Prev') }}
                        </button>
                        <button
                          :style="getButtonStyle"
                          type="button"
                          :disabled="selectedMaterialVariantPair != '' ? pagePair == getTotalItemsPages : pagePair == getTotalPagesForMVM "
                          @click="nextPair"
                        >
                          {{ $t('Next') }}
                        </button>
                      </li>
                    </vue-select>
                    <vue-select
                      v-else-if="materials.measurementType === 'Verse' && toggleSearch === 'MaterialName'"
                      v-model="materials.name"
                      :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVByVerse : getMaterialVariantColorNameLike"
                      :placeholder="$t('MaterialVariant')"
                      :reduce="(e) => e"
                      label="materialVariantName"
                      @search="onSearchVerse"
                      @input="currentStock(materials.name.materialVariantId)"
                    >
                      <li
                        slot="list-footer"
                        class="pagination"
                        style="display: flex; width: 100%; justify-content: center;"
                      >
                        <button
                          type="button"
                          :style="
                            pageM == 1
                              ? 'width: 50%; border: none;'
                              : 'width: 50%; border: none; background-color: #242F6E; color: white'
                          "
                          :disabled="pageVerse == 1"
                          @click="prevVerse"
                        >
                          {{ $t('Prev') }}
                        </button>
                        <button
                          :style="getButtonStyle"
                          type="button"
                          :disabled="selectedMaterialVariantVerse != '' ? pageVerse == getTotalItemsPages : pageVerse == getTotalPagesForMVM "
                          @click="nextVerse"
                        >
                          {{ $t('Next') }}
                        </button>
                      </li>
                    </vue-select>
                    <vue-select
                      v-else-if="materials.measurementType === 'Verse' && toggleSearch === 'Description'"
                      v-model="materials.name"
                      :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVByVerse : getFilterVariantsByCodeLike"
                      :placeholder="$t('MaterialVariant')"
                      :reduce="(e) => e"
                      label="materialVariantName"
                      @search="onSearchVerseDesc"
                      @input="currentStock(materials.name.materialVariantId)"
                    >
                      <li
                        slot="list-footer"
                        class="pagination"
                        style="display: flex; width: 100%; justify-content: center;"
                      >
                        <button
                          type="button"
                          :style="
                            pageM == 1
                              ? 'width: 50%; border: none;'
                              : 'width: 50%; border: none; background-color: #242F6E; color: white'
                          "
                          :disabled="pageVerse == 1"
                          @click="prevVerse"
                        >
                          {{ $t('Prev') }}
                        </button>
                        <button
                          :style="getButtonStyle"
                          type="button"
                          :disabled="selectedMaterialVariantVerse != '' ? pageVerse == getTotalItemsPages : pageVerse == getTotalPagesForMVM "
                          @click="nextVerse"
                        >
                          {{ $t('Next') }}
                        </button>
                      </li>
                    </vue-select>
                    <vue-select
                      v-else-if="materials.measurementType === 'Part' && toggleSearch === 'MaterialName'"
                      v-model="materials.name"
                      :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVByPart : getMaterialVariantColorNameLike"
                      :placeholder="$t('MaterialVariant')"
                      :reduce="(e) => e"
                      label="materialVariantName"
                      @search="onSearchP"
                      @input="currentStock(materials.name.materialVariantId)"
                    >
                      <li
                        slot="list-footer"
                        class="pagination"
                        style="display: flex; width: 100%; justify-content: center"
                      >
                        <button
                          type="button"
                          :style="
                            pageM == 1
                              ? 'width: 50%; border: none;'
                              : 'width: 50%; border: none; background-color: #242F6E; color: white'
                          "
                          :disabled="pageP == 1"
                          @click="prevPart"
                        >
                          {{ $t('Prev') }}
                        </button>
                        <button
                          type="button"
                          :style="getButtonStylePart"
                          :disabled="selectedMaterialVariantPart != '' ? pageP == getTotalItemsPages : pageP == getTotalPagesForMVP "
                          @click="nextPart"
                        >
                          {{ $t('Next') }}
                        </button>
                      </li>
                    </vue-select>
                    <vue-select
                      v-else-if="materials.measurementType === 'Part' && toggleSearch === 'Description'"
                      v-model="materials.name"
                      :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVByPart : getFilterVariantsByCodeLike"
                      :placeholder="$t('MaterialVariant')"
                      :reduce="(e) => e"
                      label="materialVariantName"
                      @search="onSearchPDesc"
                      @input="currentStock(materials.name.materialVariantId)"
                    >
                      <li
                        slot="list-footer"
                        class="pagination"
                        style="display: flex; width: 100%; justify-content: center"
                      >
                        <button
                          type="button"
                          :style="
                            pageM == 1
                              ? 'width: 50%; border: none;'
                              : 'width: 50%; border: none; background-color: #242F6E; color: white'
                          "
                          :disabled="pageP == 1"
                          @click="prevPart"
                        >
                          {{ $t('Prev') }}
                        </button>
                        <button
                          type="button"
                          :style="getButtonStylePart"
                          :disabled="selectedMaterialVariantPart != '' ? pageP == getTotalItemsPages : pageP == getTotalPagesForMVP "
                          @click="nextPart"
                        >
                          {{ $t('Next') }}
                        </button>
                      </li>
                    </vue-select>
                    <p
                      v-if="(materials.name == null || materials.name == '' )&& isButtonSubmited "
                      style="color: red;padding-top: 5px;
                    margin-bottom: 0px;"
                    >
                      {{ $t('Please Select a material variant') }}
                    </p>
                  </b-form-group>
                  <b-form-group
                    v-if="materials.name"
                    :label="`${$t('Real Price')}:`"
                    style="width: 100%;"
                  >
                    <b-input
                      v-model="materials.name.sellPrice"
                      type="number"
                      disabled
                    />
                  </b-form-group>
                  <b-form-group
                    v-if="materials.name"
                    :label="`${$t('Price')}:`"
                    style="width: 100%;"
                  >
                    <b-input
                      v-model="materials.pricePerMeterWithDiscount"
                      type="number"
                    />
                  </b-form-group>
                  <b-form-group
                    v-if="materials.measurementType === 'Meter'"
                    :label="`${$t('Meter')}:`"
                  >
                    <b-input
                      v-model="materials.length"
                      type="number"
                    />
                    <p style="color: black; padding-top: 5px; margin-bottom: 0px;">
                      {{ materials.name ? `${'Price'}: ${totalPriceCalc(materials.pricePerMeterWithDiscount, materials.length)}€` : null }}
                    </p>
                    <p
                      v-if="materials.length < 0.0001 && isButtonSubmited "
                      style="color: red;padding-top: 5px;
                margin-bottom: 0px;"
                    >
                      {{ $t('TheValue') }}
                    </p>
                  </b-form-group>
                  <b-form-group
                    v-else-if="materials.measurementType === 'Package'"
                    :label="`${$t('Meter')}:`"
                  >
                    <b-input
                      v-model="materials.length"
                      type="number"
                    />
                    <p style="color: black; padding-top: 5px; margin-bottom: 0px;">
                      {{ materials.name ? `${'Price'}: ${totalPriceCalc(materials.pricePerMeterWithDiscount, materials.length)}€` : null }}
                    </p>
                    <p
                      v-if="materials.length < 0.0001 && isButtonSubmited "
                      style="color: red;padding-top: 5px;
                margin-bottom: 0px;"
                    >
                      {{ $t('TheValue') }}
                    </p>
                  </b-form-group>
                  <b-form-group
                    v-else-if="materials.measurementType === 'Set'"
                    :label="`${$t('Meter')}:`"
                  >
                    <b-input
                      v-model="materials.length"
                      type="number"
                    />
                    <p style="color: black; padding-top: 5px; margin-bottom: 0px;">
                      {{ materials.name ? `${'Price'}: ${totalPriceCalc(materials.pricePerMeterWithDiscount, materials.length)}€` : null }}
                    </p>
                    <p
                      v-if="materials.length < 0.0001 && isButtonSubmited "
                      style="color: red;padding-top: 5px;
                margin-bottom: 0px;"
                    >
                      {{ $t('TheValue') }}
                    </p>
                  </b-form-group>
                  <b-form-group
                    v-else-if="materials.measurementType === 'Pair'"
                    :label="`${$t('Meter')}:`"
                  >
                    <b-input
                      v-model="materials.length"
                      type="number"
                    />
                    <p style="color: black; padding-top: 5px; margin-bottom: 0px;">
                      {{ materials.name ? `${'Price'}: ${totalPriceCalc(materials.pricePerMeterWithDiscount, materials.length)}€` : null }}
                    </p>
                    <p
                      v-if="materials.length < 0.0001 && isButtonSubmited "
                      style="color: red;padding-top: 5px;
                margin-bottom: 0px;"
                    >
                      {{ $t('TheValue') }}
                    </p>
                  </b-form-group>
                  <b-form-group
                    v-else-if="materials.measurementType === 'Verse'"
                    :label="`${$t('Meter')}:`"
                  >
                    <b-input
                      v-model="materials.length"
                      type="number"
                    />
                    <p style="color: black; padding-top: 5px; margin-bottom: 0px;">
                      {{ materials.name ? `${'Price'}: ${totalPriceCalc(materials.pricePerMeterWithDiscount, materials.length)}€` : null }}
                    </p>
                    <p
                      v-if="materials.length < 0.0001 && isButtonSubmited "
                      style="color: red;padding-top: 5px;
                margin-bottom: 0px;"
                    >
                      {{ $t('TheValue') }}
                    </p>
                  </b-form-group>
                  <b-form-group
                    v-else
                    :label="`${$t('Part')}:`"
                  >
                    <b-input
                      v-model="materials.quantity"
                      type="number"
                    />
                    <p style="color: black; padding-top: 5px; margin-bottom: 0px;">
                      {{ materials.name ? `${'Price'}: ${totalPriceCalc(materials.pricePerMeter, materials.quantity)}€` : null }}
                    </p>
                    <p
                      v-if="materials.quantity < 0.0001 && isButtonSubmited "
                      style="color: red;padding-top: 5px;
                margin-bottom: 0px;"
                    >
                      {{ $t('TheValue') }}
                    </p>
                  </b-form-group>
                  <b-form-group
                    v-if="materials.name ? materials.name.materialVariantId === clickedMaterialVariantId : false"
                    :label="`${$t('Stock')}:`"
                    style="width: 100%;"
                  >
                    <b-form-input
                      v-model="getSavedStock.stockValue"
                      type="number"
                      disabled
                    />
                  </b-form-group>
                </div>
              </div>
              <div
                v-for="(windowDecorative, windowDecorativeIndex) in window.createWindowDecorativeRequestDtos"
                :key="windowDecorativeIndex"
                class="custom-divs3"
              >
                <div v-if="window.hingType == 'Decorative' || window.hingType == 'Closed'">
                  <div style="display: flex;justify-content: space-between;">
                    <h1 style="font-size: 30px;display:flex;align-items: center;gap:10px;">
                      <img :src="require(`../../assets/images/floor${Math.min(windowDecorativeIndex + 1, 3)}.svg`)">
                      <p
                        style="margin:0px"
                      >
                        {{ window.name }} - {{ window.hingType }}
                      </p>
                    </h1>
                    <div style="display: flex;gap: 5px;">
                      <button
                        class="button"
                        type="button"
                        @click="addMaterialDecorative(index, floorIndex, roomIndex,windowIndex,windowDecorativeIndex)"
                      >
                        Shto Materiale
                      </button>
                    </div>
                  </div>
                  <hr style="color:gray;margin:7px !important;">

                  <div style="margin-top: 10px;">
                    <div style="display:flex;width:100%;margin-bottom:15px">
                      <b-form-group
                        style="width:80%"
                        :label="$t('Transport')"
                      >
                        <b-form-checkbox v-model="windowDecorative.orderOtherService.transport" />
                      </b-form-group>
                      <b-form-group
                        style="width:80%"
                        :label="$t('Montage')"
                      >
                        <b-form-checkbox v-model="windowDecorative.orderOtherService.montage" />
                      </b-form-group>
                    </div>

                    <div style="display: flex;gap: 25px">
                      <b-form-group
                        :label="$t('Width')"
                        style="width:100%"
                      >
                        <b-form-input
                          v-model="windowDecorative.width"
                        />
                        <p
                          v-if="windowDecorative.width < 0.0001"
                          style="color: red;padding-top: 5px;
                    margin-bottom: 0px;"
                        >
                          {{ $t('TheValue') }}
                        </p>
                      </b-form-group>
                      <b-form-group
                        :label="$t('Height')"
                        style="width:100%"
                      >
                        <b-form-input
                          v-model="windowDecorative.height"
                        />
                        <p
                          v-if="windowDecorative.height < 0.0001"
                          style="color: red;padding-top: 5px;
                    margin-bottom: 0px;"
                        >
                          {{ $t('TheValue') }}
                        </p>
                      </b-form-group>
                      <b-form-group
                        :label="$t('SewingType')"
                        style="width:100%"
                      >
                        <vue-select
                          v-model="windowDecorative.sewingType"
                          :options="sewingTypes"
                          label="name"
                          :placeholder="$t('Select')"
                        />
                        <p
                          v-if="windowDecorative.sewingType == null"
                          style="color: red;padding-top: 5px;
                    margin-bottom: 0px;"
                        >
                          {{ $t('Please select a sewing type') }}
                        </p>
                      </b-form-group>
                    </div>
                    <div
                      style="margin-top: 10px;"
                      class="modal-card__wrapper1"
                    >
                      <div
                        v-for="(materials, index2) in windowDecorative.materialVariantInStoreDtos"
                        :key="index2"
                        class="modal-card"
                      >
                        <b-form-group
                          id="input-group-3"
                          :label="`${$t('SelectMaterialType')}:`"
                        >
                          <i
                            v-if="windowDecorative.materialVariantInStoreDtos.length > 1"
                            class="modal-card__remove"
                            @click="removeDecorativeMaterialItemFromCD(index, floorIndex, roomIndex,windowIndex,windowDecorativeIndex,index2)"
                          >
                            <b-icon-trash />
                          </i>
                          <div
                            class="flex radio-form"
                            style="justify-content: space-between;"
                          >
                            <div style="display: grid; grid-template-columns: repeat(3, 1fr);">
                              <div
                                :id="`one${index2}`"
                                class="flex aic"
                              >
                                <input
                                  v-model="materials.measurementType"
                                  type="radio"
                                  value="Meter"
                                  @click="resetMeter(materials)"
                                >
                                <label :for="`one${index2}`">{{ $t('Meter') }}</label>
                              </div>
                              <div
                                :id="`one${index2}`"
                                class="flex aic"
                              >
                                <input
                                  v-model="materials.measurementType"
                                  type="radio"
                                  value="Package"
                                  @click="resetPackage(materials)"
                                >
                                <label :for="`one${index2}`">{{ $t('Package') }}</label>
                              </div>
                              <div
                                :id="`one${index2}`"
                                class="flex aic"
                              >
                                <input
                                  v-model="materials.measurementType"
                                  type="radio"
                                  value="Set"
                                  @click="resetSet(materials)"
                                >
                                <label :for="`one${index2}`">{{ $t('Set') }}</label>
                              </div>
                              <div
                                :id="`one${index2}`"
                                class="flex aic"
                              >
                                <input
                                  v-model="materials.measurementType"
                                  type="radio"
                                  value="Pair"
                                  @click="resetPair(materials)"
                                >
                                <label :for="`one${index2}`">{{ $t('Pair') }}</label>
                              </div>
                              <div
                                :id="`one${index2}`"
                                class="flex aic"
                              >
                                <input
                                  v-model="materials.measurementType"
                                  type="radio"
                                  value="Verse"
                                  @click="resetVerse(materials)"
                                >
                                <label :for="`one${index2}`">{{ $t('Beaded strand') }}</label>
                              </div>
                              <div
                                :id="`two${index2}`"
                                class="flex aic"
                              >
                                <input
                                  v-model="materials.measurementType"
                                  type="radio"
                                  value="Part"
                                  @click="resetPart(materials)"
                                >
                                <label :for="`two${index2}`">  {{ $t('Part') }}</label>
                              </div>
                            </div>
                            <td>
                              <img
                                v-if="materials.name != null && materials.name != '' "
                                v-b-tooltip="$t('ClickToViewPhoto')"
                                style="height: 30px; border-radius: 6px;cursor: pointer "
                                src="../../assets/images/imageicon.svg"
                                alt=""
                                @click.stop="getAllImages(materials.name.materialVariantId)"
                              >
                            </td>
                          </div>
                        </b-form-group>
                        <div class="search-toggle">

                          <p>{{ $t('SearchBy') }}</p>
                          <div>
                            <p
                              :class="{ active2: toggleSearch === 'MaterialName' }"
                              @click="showMaterialName(), toggleSearch = 'MaterialName'"
                            >
                              {{ $t('Name') }}
                            </p>
                            <p
                              :class="{ active2: toggleSearch === 'Description' }"
                              @click="showDescription(), toggleSearch = 'Description'"
                            >
                              {{ $t('Description') }}
                            </p>
                          </div>
                        </div>
                        <b-form-group
                          id="input-select-1"
                          label-for="select-1"
                        >
                          <vue-select
                            v-if="materials.measurementType === 'Meter' && toggleSearch === 'Description'"
                            v-model="materials.name"
                            :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVByMeter : getFilterVariantsByCodeLike"
                            :placeholder="$t('Description')"
                            :reduce="(e) => e"
                            label="materialVariantName"
                            @search="onSearchMDesc"
                            @input="currentStock(materials.name.materialVariantId)"
                          >
                            <li
                              slot="list-footer"
                              class="pagination"
                              style="display: flex; width: 100%; justify-content: center;"
                            >
                              <button
                                type="button"
                                :style="
                                  pageM == 1
                                    ? 'width: 50%; border: none;'
                                    : 'width: 50%; border: none; background-color: #242F6E; color: white'
                                "
                                :disabled="pageM == 1"
                                @click="prevMeter"
                              >
                                {{ $t('Prev') }}
                              </button>
                              <button
                                :style="getButtonStyle"
                                type="button"
                                :disabled="selectedMaterialVariant != '' ? pageM == getTotalItemsPages : pageM == getTotalPagesForMVM "
                                @click="nextMeter"
                              >
                                {{ $t('Next') }}
                              </button>
                            </li>
                          </vue-select>
                          <vue-select
                            v-else-if="materials.measurementType === 'Meter' && toggleSearch === 'MaterialName'"
                            v-model="materials.name"
                            :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVByMeter : getMaterialVariantColorNameLike"
                            :placeholder="$t('MaterialVariant')"
                            :reduce="(e) => e"
                            label="materialVariantName"
                            @search="onSearchM"
                            @input="currentStock(materials.name.materialVariantId)"
                          >
                            <li
                              slot="list-footer"
                              class="pagination"
                              style="display: flex; width: 100%; justify-content: center;"
                            >
                              <button
                                type="button"
                                :style="
                                  pageM == 1
                                    ? 'width: 50%; border: none;'
                                    : 'width: 50%; border: none; background-color: #242F6E; color: white'
                                "
                                :disabled="pageM == 1"
                                @click="prevMeter"
                              >
                                {{ $t('Prev') }}
                              </button>
                              <button
                                :style="getButtonStyle"
                                type="button"
                                :disabled="selectedMaterialVariant != '' ? pageM == getTotalItemsPages : pageM == getTotalPagesForMVM "
                                @click="nextMeter"
                              >
                                {{ $t('Next') }}
                              </button>
                            </li>
                          </vue-select>
                          <vue-select
                            v-else-if="materials.measurementType === 'Package' && toggleSearch === 'MaterialName'"
                            v-model="materials.name"
                            :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVByPackage : getMaterialVariantColorNameLike"
                            :placeholder="$t('MaterialVariant')"
                            :reduce="(e) => e"
                            label="materialVariantName"
                            @search="onSearchPack"
                            @input="currentStock(materials.name.materialVariantId)"
                          >
                            <li
                              slot="list-footer"
                              class="pagination"
                              style="display: flex; width: 100%; justify-content: center;"
                            >
                              <button
                                type="button"
                                :style="
                                  pageM == 1
                                    ? 'width: 50%; border: none;'
                                    : 'width: 50%; border: none; background-color: #242F6E; color: white'
                                "
                                :disabled="pagePack == 1"
                                @click="prevPackage"
                              >
                                {{ $t('Prev') }}
                              </button>
                              <button
                                :style="getButtonStyle"
                                type="button"
                                :disabled="selectedMaterialVariantPackage != '' ? pagePack == getTotalItemsPages : pagePack == getTotalPagesForMVM "
                                @click="nextPackage"
                              >
                                {{ $t('Next') }}
                              </button>
                            </li>
                          </vue-select>
                          <vue-select
                            v-else-if="materials.measurementType === 'Package' && toggleSearch === 'Description'"
                            v-model="materials.name"
                            :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVByPackage : getFilterVariantsByCodeLike"
                            :placeholder="$t('Description')"
                            :reduce="(e) => e"
                            label="materialVariantName"
                            @search="onSearchPackDesc"
                            @input="currentStock(materials.name.materialVariantId)"
                          >
                            <li
                              slot="list-footer"
                              class="pagination"
                              style="display: flex; width: 100%; justify-content: center;"
                            >
                              <button
                                type="button"
                                :style="
                                  pageM == 1
                                    ? 'width: 50%; border: none;'
                                    : 'width: 50%; border: none; background-color: #242F6E; color: white'
                                "
                                :disabled="pagePack == 1"
                                @click="prevPackage"
                              >
                                {{ $t('Prev') }}
                              </button>
                              <button
                                :style="getButtonStyle"
                                type="button"
                                :disabled="selectedMaterialVariantPackage != '' ? pagePack == getTotalItemsPages : pagePack == getTotalPagesForMVM "
                                @click="nextPackage"
                              >
                                {{ $t('Next') }}
                              </button>
                            </li>
                          </vue-select>
                          <vue-select
                            v-else-if="materials.measurementType === 'Set' && toggleSearch === 'MaterialName'"
                            v-model="materials.name"
                            :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVBySet : getMaterialVariantColorNameLike"
                            :placeholder="$t('MaterialVariant')"
                            :reduce="(e) => e"
                            label="materialVariantName"
                            @search="onSearchSet"
                            @input="currentStock(materials.name.materialVariantId)"
                          >
                            <li
                              slot="list-footer"
                              class="pagination"
                              style="display: flex; width: 100%; justify-content: center;"
                            >
                              <button
                                type="button"
                                :style="
                                  pageM == 1
                                    ? 'width: 50%; border: none;'
                                    : 'width: 50%; border: none; background-color: #242F6E; color: white'
                                "
                                :disabled="pageSet == 1"
                                @click="prevSet"
                              >
                                {{ $t('Prev') }}
                              </button>
                              <button
                                :style="getButtonStyle"
                                type="button"
                                :disabled="selectedMaterialVariantSet != '' ? pageSet == getTotalItemsPages : pageSet == getTotalPagesForMVM "
                                @click="nextSet"
                              >
                                {{ $t('Next') }}
                              </button>
                            </li>
                          </vue-select>
                          <vue-select
                            v-else-if="materials.measurementType === 'Set' && toggleSearch === 'Description'"
                            v-model="materials.name"
                            :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVBySet : getFilterVariantsByCodeLike"
                            :placeholder="$t('Description')"
                            :reduce="(e) => e"
                            label="materialVariantName"
                            @search="onSearchSetDesc"
                            @input="currentStock(materials.name.materialVariantId)"
                          >
                            <li
                              slot="list-footer"
                              class="pagination"
                              style="display: flex; width: 100%; justify-content: center;"
                            >
                              <button
                                type="button"
                                :style="
                                  pageM == 1
                                    ? 'width: 50%; border: none;'
                                    : 'width: 50%; border: none; background-color: #242F6E; color: white'
                                "
                                :disabled="pageSet == 1"
                                @click="prevSet"
                              >
                                {{ $t('Prev') }}
                              </button>
                              <button
                                :style="getButtonStyle"
                                type="button"
                                :disabled="selectedMaterialVariantSet != '' ? pageSet == getTotalItemsPages : pageSet == getTotalPagesForMVM "
                                @click="nextSet"
                              >
                                {{ $t('Next') }}
                              </button>
                            </li>
                          </vue-select>
                          <vue-select
                            v-else-if="materials.measurementType === 'Pair' && toggleSearch === 'MaterialName'"
                            v-model="materials.name"
                            :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVByPair : getMaterialVariantColorNameLike"
                            :placeholder="$t('MaterialVariant')"
                            :reduce="(e) => e"
                            label="materialVariantName"
                            @search="onSearchPair"
                            @input="currentStock(materials.name.materialVariantId)"
                          >
                            <li
                              slot="list-footer"
                              class="pagination"
                              style="display: flex; width: 100%; justify-content: center;"
                            >
                              <button
                                type="button"
                                :style="
                                  pageM == 1
                                    ? 'width: 50%; border: none;'
                                    : 'width: 50%; border: none; background-color: #242F6E; color: white'
                                "
                                :disabled="pagePair == 1"
                                @click="prevPair"
                              >
                                {{ $t('Prev') }}
                              </button>
                              <button
                                :style="getButtonStyle"
                                type="button"
                                :disabled="selectedMaterialVariantPair != '' ? pagePair == getTotalItemsPages : pagePair == getTotalPagesForMVM "
                                @click="nextPair"
                              >
                                {{ $t('Next') }}
                              </button>
                            </li>
                          </vue-select>
                          <vue-select
                            v-else-if="materials.measurementType === 'Pair' && toggleSearch === 'Description'"
                            v-model="materials.name"
                            :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVByPair : getFilterVariantsByCodeLike"
                            :placeholder="$t('MaterialVariant')"
                            :reduce="(e) => e"
                            label="materialVariantName"
                            @search="onSearchPairDesc"
                            @input="currentStock(materials.name.materialVariantId)"
                          >
                            <li
                              slot="list-footer"
                              class="pagination"
                              style="display: flex; width: 100%; justify-content: center;"
                            >
                              <button
                                type="button"
                                :style="
                                  pageM == 1
                                    ? 'width: 50%; border: none;'
                                    : 'width: 50%; border: none; background-color: #242F6E; color: white'
                                "
                                :disabled="pagePair == 1"
                                @click="prevPair"
                              >
                                {{ $t('Prev') }}
                              </button>
                              <button
                                :style="getButtonStyle"
                                type="button"
                                :disabled="selectedMaterialVariantPair != '' ? pagePair == getTotalItemsPages : pagePair == getTotalPagesForMVM "
                                @click="nextPair"
                              >
                                {{ $t('Next') }}
                              </button>
                            </li>
                          </vue-select>
                          <vue-select
                            v-else-if="materials.measurementType === 'Verse' && toggleSearch === 'MaterialName'"
                            v-model="materials.name"
                            :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVByVerse : getMaterialVariantColorNameLike"
                            :placeholder="$t('MaterialVariant')"
                            :reduce="(e) => e"
                            label="materialVariantName"
                            @search="onSearchVerse"
                            @input="currentStock(materials.name.materialVariantId)"
                          >
                            <li
                              slot="list-footer"
                              class="pagination"
                              style="display: flex; width: 100%; justify-content: center;"
                            >
                              <button
                                type="button"
                                :style="
                                  pageM == 1
                                    ? 'width: 50%; border: none;'
                                    : 'width: 50%; border: none; background-color: #242F6E; color: white'
                                "
                                :disabled="pageVerse == 1"
                                @click="prevVerse"
                              >
                                {{ $t('Prev') }}
                              </button>
                              <button
                                :style="getButtonStyle"
                                type="button"
                                :disabled="selectedMaterialVariantVerse != '' ? pageVerse == getTotalItemsPages : pageVerse == getTotalPagesForMVM "
                                @click="nextVerse"
                              >
                                {{ $t('Next') }}
                              </button>
                            </li>
                          </vue-select>
                          <vue-select
                            v-else-if="materials.measurementType === 'Verse' && toggleSearch === 'Description'"
                            v-model="materials.name"
                            :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVByVerse : getFilterVariantsByCodeLike"
                            :placeholder="$t('MaterialVariant')"
                            :reduce="(e) => e"
                            label="materialVariantName"
                            @search="onSearchVerseDesc"
                            @input="currentStock(materials.name.materialVariantId)"
                          >
                            <li
                              slot="list-footer"
                              class="pagination"
                              style="display: flex; width: 100%; justify-content: center;"
                            >
                              <button
                                type="button"
                                :style="
                                  pageM == 1
                                    ? 'width: 50%; border: none;'
                                    : 'width: 50%; border: none; background-color: #242F6E; color: white'
                                "
                                :disabled="pageVerse == 1"
                                @click="prevVerse"
                              >
                                {{ $t('Prev') }}
                              </button>
                              <button
                                :style="getButtonStyle"
                                type="button"
                                :disabled="selectedMaterialVariantVerse != '' ? pageVerse == getTotalItemsPages : pageVerse == getTotalPagesForMVM "
                                @click="nextVerse"
                              >
                                {{ $t('Next') }}
                              </button>
                            </li>
                          </vue-select>
                          <vue-select
                            v-else-if="materials.measurementType === 'Part' && toggleSearch === 'MaterialName'"
                            v-model="materials.name"
                            :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVByPart : getMaterialVariantColorNameLike"
                            :placeholder="$t('MaterialVariant')"
                            :reduce="(e) => e"
                            label="materialVariantName"
                            @search="onSearchP"
                            @input="currentStock(materials.name.materialVariantId)"
                          >
                            <li
                              slot="list-footer"
                              class="pagination"
                              style="display: flex; width: 100%; justify-content: center"
                            >
                              <button
                                type="button"
                                :style="
                                  pageM == 1
                                    ? 'width: 50%; border: none;'
                                    : 'width: 50%; border: none; background-color: #242F6E; color: white'
                                "
                                :disabled="pageP == 1"
                                @click="prevPart"
                              >
                                {{ $t('Prev') }}
                              </button>
                              <button
                                type="button"
                                :style="getButtonStylePart"
                                :disabled="selectedMaterialVariantPart != '' ? pageP == getTotalItemsPages : pageP == getTotalPagesForMVP "
                                @click="nextPart"
                              >
                                {{ $t('Next') }}
                              </button>
                            </li>
                          </vue-select>
                          <vue-select
                            v-else-if="materials.measurementType === 'Part' && toggleSearch === 'Description'"
                            v-model="materials.name"
                            :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVByPart : getFilterVariantsByCodeLike"
                            :placeholder="$t('MaterialVariant')"
                            :reduce="(e) => e"
                            label="materialVariantName"
                            @search="onSearchPDesc"
                            @input="currentStock(materials.name.materialVariantId)"
                          >
                            <li
                              slot="list-footer"
                              class="pagination"
                              style="display: flex; width: 100%; justify-content: center"
                            >
                              <button
                                type="button"
                                :style="
                                  pageM == 1
                                    ? 'width: 50%; border: none;'
                                    : 'width: 50%; border: none; background-color: #242F6E; color: white'
                                "
                                :disabled="pageP == 1"
                                @click="prevPart"
                              >
                                {{ $t('Prev') }}
                              </button>
                              <button
                                type="button"
                                :style="getButtonStylePart"
                                :disabled="selectedMaterialVariantPart != '' ? pageP == getTotalItemsPages : pageP == getTotalPagesForMVP "
                                @click="nextPart"
                              >
                                {{ $t('Next') }}
                              </button>
                            </li>
                          </vue-select>
                          <p
                            v-if="(materials.name == null || materials.name == '' )"
                            style="color: red;padding-top: 5px;
                    margin-bottom: 0px;"
                          >
                            {{ $t('Please Select a material variant') }}
                          </p>
                        </b-form-group>
                        <b-form-group
                          v-if="materials.name"
                          :label="`${$t('Real Price')}:`"
                          style="width: 100%;"
                        >
                          <b-input
                            v-model="materials.name.sellPrice"
                            type="number"
                            disabled
                          />
                        </b-form-group>
                        <b-form-group
                          v-if="materials.name"
                          :label="`${$t('Price')}:`"
                          style="width: 100%;"
                        >
                          <b-input
                            v-model="materials.pricePerMeterWithDiscount"
                            type="number"
                          />
                        </b-form-group>
                        <b-form-group
                          v-if="materials.measurementType === 'Meter'"
                          :label="`${$t('Meter')}:`"
                        >
                          <b-input
                            v-model="materials.length"
                            type="number"
                          />
                          <p style="color: black; padding-top: 5px; margin-bottom: 0px;">
                            {{ materials.name ? `${'Price'}: ${totalPriceCalc(materials.pricePerMeterWithDiscount, materials.length)}€` : null }}
                          </p>
                          <p
                            v-if="materials.length < 0.0001"
                            style="color: red;padding-top: 5px;
                margin-bottom: 0px;"
                          >
                            {{ $t('TheValue') }}
                          </p>
                        </b-form-group>
                        <b-form-group
                          v-else-if="materials.measurementType === 'Package'"
                          :label="`${$t('Meter')}:`"
                        >
                          <b-input
                            v-model="materials.length"
                            type="number"
                          />
                          <p style="color: black; padding-top: 5px; margin-bottom: 0px;">
                            {{ materials.name ? `${'Price'}: ${totalPriceCalc(materials.pricePerMeterWithDiscount, materials.length)}€` : null }}
                          </p>
                          <p
                            v-if="materials.length < 0.0001"
                            style="color: red;padding-top: 5px;
                margin-bottom: 0px;"
                          >
                            {{ $t('TheValue') }}
                          </p>
                        </b-form-group>
                        <b-form-group
                          v-else-if="materials.measurementType === 'Set'"
                          :label="`${$t('Meter')}:`"
                        >
                          <b-input
                            v-model="materials.length"
                            type="number"
                          />
                          <p style="color: black; padding-top: 5px; margin-bottom: 0px;">
                            {{ materials.name ? `${'Price'}: ${totalPriceCalc(materials.pricePerMeterWithDiscount, materials.length)}€` : null }}
                          </p>
                          <p
                            v-if="materials.length < 0.0001"
                            style="color: red;padding-top: 5px;
                margin-bottom: 0px;"
                          >
                            {{ $t('TheValue') }}
                          </p>
                        </b-form-group>
                        <b-form-group
                          v-else-if="materials.measurementType === 'Pair'"
                          :label="`${$t('Meter')}:`"
                        >
                          <b-input
                            v-model="materials.length"
                            type="number"
                          />
                          <p style="color: black; padding-top: 5px; margin-bottom: 0px;">
                            {{ materials.name ? `${'Price'}: ${totalPriceCalc(materials.pricePerMeterWithDiscount, materials.length)}€` : null }}
                          </p>
                          <p
                            v-if="materials.length < 0.0001"
                            style="color: red;padding-top: 5px;
                margin-bottom: 0px;"
                          >
                            {{ $t('TheValue') }}
                          </p>
                        </b-form-group>
                        <b-form-group
                          v-else-if="materials.measurementType === 'Verse'"
                          :label="`${$t('Meter')}:`"
                        >
                          <b-input
                            v-model="materials.length"
                            type="number"
                          />
                          <p style="color: black; padding-top: 5px; margin-bottom: 0px;">
                            {{ materials.name ? `${'Price'}: ${totalPriceCalc(materials.pricePerMeterWithDiscount, materials.length)}€` : null }}
                          </p>
                          <p
                            v-if="materials.length < 0.0001"
                            style="color: red;padding-top: 5px;
                margin-bottom: 0px;"
                          >
                            {{ $t('TheValue') }}
                          </p>
                        </b-form-group>
                        <b-form-group
                          v-else
                          :label="`${$t('Part')}:`"
                        >
                          <b-input
                            v-model="materials.quantity"
                            type="number"
                          />
                          <p style="color: black; padding-top: 5px; margin-bottom: 0px;">
                            {{ materials.name ? `${'Price'}: ${totalPriceCalc(materials.pricePerMeter, materials.quantity)}€` : null }}
                          </p>
                          <p
                            v-if="materials.quantity < 0.0001"
                            style="color: red;padding-top: 5px;
                margin-bottom: 0px;"
                          >
                            {{ $t('TheValue') }}
                          </p>
                        </b-form-group>
                        <b-form-group
                          v-if="materials.name ? materials.name.materialVariantId === clickedMaterialVariantId : false"
                          :label="`${$t('Stock')}:`"
                          style="width: 100%;"
                        >
                          <b-form-input
                            v-model="getSavedStock.stockValue"
                            type="number"
                            disabled
                          />
                        </b-form-group>
                      </div>
                    </div>
                  </div>
                </div>
                <p
                  v-if="windowDecorative.materialVariantInStoreDtos.length == 0 && window.hingType"
                  style="color: red;padding-top: 5px;
                margin-top: 15px;"
                >
                  {{ $t('AddMaterials') }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div></section>
    <vue-easy-lightbox
      :esc-disabled="false"
      :visible="image.visible"
      :imgs="image.imgs"
      :index="image.index"
      @hide="handleHide"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VueEasyLightbox from 'vue-easy-lightbox'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  components: {
    VueEasyLightbox,
    DatePicker,
  },
  props: ['createHouseItemRequestDtos', 'isButtonSubmited'],
  data() {
    return {
      pickupDateValue: true,
      montageValue: false,
      toggleSearch: 'MaterialName',
      clickedMaterialVariantId: null,
      selectedMaterialVariantPackage: '',
      selectedMaterialVariant: '',
      selectedMaterialVariantPart: '',
      selectedMaterialVariantSet: '',
      selectedMaterialVariantPair: '',
      selectedMaterialVariantVerse: '',
      pageM: 1,
      pageP: 1,
      pagePack: 1,
      pageSet: 1,
      pagePair: 1,
      pageVerse: 1,
      image: {
        zoom: 1,
        imgs: [], // Img Url , string or Array of string
        visible: false,
        index: 0, //
      },
      hingeTypes: [
        { name: 'Decorative', value: 'Decorative' },
        { name: 'Closed', value: 'Closed' },
      ],
      sewingTypes: [
        { name: 'Sistemi Amerikan 3m', value: 'American_3m', meter: 3 },
        { name: 'Sistemi Amerikan 2.8m', value: 'American_2_8m', meter: 2.8 },
        { name: 'Fallta te rregullta 3m', value: 'FalltaReglar_3m', meter: 3 },
        { name: 'Fallta te rregullta 2.5m', value: 'FalltaReglar_2_5m', meter: 2.5 },
        { name: 'Fallta te rregullta 2m', value: 'FalltaReglar_2m', meter: 2 },
        { name: 'Fallta Zvicrrane 3m', value: 'FalltaSwiss_3m', meter: 3 },
        { name: 'Fallta Zvicrrane 2.7m', value: 'FalltaSwiss_2_7m', meter: 2.7 },
        { name: 'Fallta Zvicrrane 2m', value: 'FalltaSwiss_2_5m', meter: 2.5 },
        { name: 'Qepje me vega 2.5m', value: 'Veg_2_5m', meter: 2.5 },
        { name: 'Qepje Fallta me vrime 3m', value: 'Holes_3m', meter: 3 },
        { name: 'Qepje Fallta me vrime 2.5m', value: 'Holes_2_5m', meter: 2.5 },
        { name: 'Qepje me rrudhje 4m', value: 'Wrinkle_4m', meter: 4 },
        { name: 'Qepje me rrudhje 3m', value: 'Wrinkle_3m', meter: 3 },
        { name: 'Qepje shirit drejt', value: 'StraightBand', meter: 1 },
      ],
      homeEditable: false,
      floorEditable: false,
      roomEditable: false,
      windowEditable: false,
    }
  },
  computed: {
    ...mapGetters(['getTotalItemsPages', 'getMaterialVariantColorNameLike', 'getImagesProduct', 'getMaterialsVByMeter', 'getSizeTypes',
      'getMaterialsVByPart', 'getFilteredMV', 'getTotalPagesForMVP',
      'getTotalPagesForMVM', 'getCurrentPageForMVM', 'getCurrentPageForMVP', 'getSavedStock', 'getProductSize',
      'getMeasermentsById', 'getMaterialsVByPackage', 'getMaterialsVBySet', 'getMaterialsVByVerse', 'getMaterialsVByPair', 'getFilterVariantsByCodeLike']),
    isMaterialVariantIdEmpty() {
      return this.dress.formItem.some((item) => { return item.name === '' });
    },
    getButtonStyle() {
      if (this.selectedMaterialVariant !== '') {
        return this.pageM == this.getTotalItemsPages
          ? { width: '50%', border: 'none' }
          : {
            width: '50%', border: 'none', backgroundColor: '#242F6E', color: 'white',
          };
      }
      return this.pageM == this.getTotalPagesForMVM
        ? { width: '50%', border: 'none' }
        : {
          width: '50%', border: 'none', backgroundColor: '#242F6E', color: 'white',
        };
    },
    getButtonStylePart() {
      if (this.selectedMaterialVariantPart !== '') {
        return this.pageM == this.getTotalItemsPages
          ? { width: '50%', border: 'none' }
          : {
            width: '50%', border: 'none', backgroundColor: '#242F6E', color: 'white',
          };
      }
      return this.pageM == this.getTotalPagesForMVP
        ? { width: '50%', border: 'none' }
        : {
          width: '50%', border: 'none', backgroundColor: '#242F6E', color: 'white',
        };
    },
  },
  watch: {
    createHouseItemRequestDtos: {
      handler(newVal) {
        newVal.forEach((house) => {
          house.createFloorRequestDtos.forEach((floor) => {
            floor.createRoomRequestDtos.forEach((room) => {
              room.createWindowRequestDtos.forEach((window) => {
                if (window.orderOtherService?.montage) {
                  window.montageValue = true;
                } else {
                  window.montageValue = false;
                }
                window.materialVariantInStoreDtos.forEach((materials) => {
                  this.$watch(
                    () => { return materials.measurementType },
                    (newWidth, oldWidth) => {
                      if (newWidth !== oldWidth) {
                        this.calculateAllLengths();
                      }
                    },
                  );
                })
                this.$watch(
                  () => { return window.materialVariantInStoreDtos.length },
                  (newLength, oldLength) => {
                    if (newLength !== oldLength) {
                      this.calculateAllLengths();
                    }
                  },
                );
                this.$watch(
                  () => { return window.width },
                  (newWidth, oldWidth) => {
                    if (newWidth !== oldWidth) {
                      this.calculateAllLengths();
                    }
                  },
                );

                this.$watch(
                  () => { return window.sewingType?.value },
                  (newSewingTypeValue, oldSewingTypeValue) => {
                    if (newSewingTypeValue !== oldSewingTypeValue) {
                      this.calculateAllLengths();
                    }
                  },
                );

                window.createWindowDecorativeRequestDtos.forEach((decorative) => {
                  this.$watch(
                    () => { return decorative.materialVariantInStoreDtos.length },
                    (newLength, oldLength) => {
                      if (newLength !== oldLength) {
                        this.calculateAllLengths();
                      }
                    },
                  );
                  decorative.materialVariantInStoreDtos.forEach((materialsDecorative) => {
                    this.$watch(
                      () => { return materialsDecorative.measurementType },
                      (newWidth, oldWidth) => {
                        if (newWidth !== oldWidth) {
                          this.calculateAllLengths();
                        }
                      },
                    );
                  })
                  if (decorative.orderOtherService?.montage) {
                    decorative.montageValue = true;
                  } else {
                    decorative.montageValue = false;
                  }
                  this.$watch(
                    () => { return decorative.width },
                    (newWidth, oldWidth) => {
                      if (newWidth !== oldWidth) {
                        this.calculateAllLengths();
                      }
                    },
                  );
                  this.$watch(
                    () => { return decorative.sewingType?.value },
                    (newSewingTypeValue, oldSewingTypeValue) => {
                      if (newSewingTypeValue !== oldSewingTypeValue) {
                        this.calculateAllLengths();
                      }
                    },
                  );
                });

                if (!window.previousHingType) {
                  window.previousHingType = window.hingType;
                }
                if (window.hingType !== window.previousHingType) {
                  if (window.hingType === null || window.hingType === '') {
                    window.createWindowDecorativeRequestDtos = [];
                  } else {
                    window.createWindowDecorativeRequestDtos.forEach((decorative) => {
                      decorative.width = 0;
                      decorative.height = 0;
                      decorative.pattern = 0;
                      decorative.sewing = 0;
                      decorative.transport = true;
                      decorative.montage = true;
                      decorative.sewingType.value = null;
                      decorative.montageValue = false
                      decorative.materialVariantInStoreDtos.forEach((materialVariant) => {
                        materialVariant.materialVariantId = null;
                        materialVariant.name = null;
                        materialVariant.measurementType = 'Meter';
                        materialVariant.quantity = 0;
                        materialVariant.length = 0;
                        materialVariant.pricePerMeter = 0;
                        materialVariant.pricePerMeterWithDiscount = 0;
                      });
                    });
                  }
                  window.previousHingType = window.hingType;
                }
              });
            });
          });
        });
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(['filterVariantByColor',
      'currentStockByVariant',
      'getImagesByMaterialVariantId',
      'getMaterialVariantsToMDCPagination',
      'loadSizeTypes',
      'testResetMV',
      'resetAllFiltered',
      'loadMaterialVaraintsCategoryNameLike',
      'filterVariantByCode']),
    calculateLength(width, sewing) {
      return width * sewing
    },
    calculateAllLengths() {
      this.createHouseItemRequestDtos.forEach((house) => {
        house.createFloorRequestDtos.forEach((floor) => {
          floor.createRoomRequestDtos.forEach((room) => {
            room.createWindowRequestDtos.forEach((window) => {
              const width = window.width;
              const sewingType = window.sewingType.meter;
              window.materialVariantInStoreDtos.forEach((material) => {
                if (material.measurementType === 'Part') {
                  material.quantity = this.calculateLength(width, sewingType);
                } else {
                  material.length = this.calculateLength(width, sewingType);
                }
              });
              window.createWindowDecorativeRequestDtos.forEach((decorative) => {
                const widthDecorative = decorative.width;
                const sewingTypeDecorative = decorative.sewingType.meter;
                decorative.materialVariantInStoreDtos.forEach((material) => {
                  if (material.measurementType === 'Part') {
                    material.quantity = this.calculateLength(widthDecorative, sewingTypeDecorative);
                  } else {
                    material.length = this.calculateLength(widthDecorative, sewingTypeDecorative);
                  }
                });
              })
            });
          });
        });
      });
    },
    addFloor(index) {
      const newFloor = this.createHouseItemRequestDtos[index].createFloorRequestDtos.length + 1;
      this.createHouseItemRequestDtos[index].createFloorRequestDtos.push({ name: `Kati nr ${newFloor}`, createRoomRequestDtos: [], editable: false });
    },
    addRoom(index, floorIndex) {
      const newRoom = this.createHouseItemRequestDtos[index].createFloorRequestDtos[floorIndex].createRoomRequestDtos.length + 1;
      this.createHouseItemRequestDtos[index].createFloorRequestDtos[floorIndex].createRoomRequestDtos.push({ name: `Dhoma nr ${newRoom}`, createWindowRequestDtos: [], editable: false });
    },
    addWindow(index, floorIndex, roomIndex) {
      const newWindow = this.createHouseItemRequestDtos[index].createFloorRequestDtos[floorIndex].createRoomRequestDtos[roomIndex].createWindowRequestDtos.length + 1;
      this.createHouseItemRequestDtos[index].createFloorRequestDtos[floorIndex].createRoomRequestDtos[roomIndex].createWindowRequestDtos.push({
        name: `Objekti nr ${newWindow}`,
        width: 0,
        height: 0,
        sewingType: null,
        hingType: null,
        orderOtherService: {
          pattern: true,
          sewing: true,
          transport: true,
          montage: true,
        },
        montageValue: false,
        editable: false,
        materialVariantInStoreDtos: [
          {
            materialVariantId: null,
            name: null,
            measurementType: 'Meter',
            quantity: 0,
            length: 0,
            pricePerMeter: 0,
            pricePerMeterWithDiscount: 0,
          },
        ],
        montageStart: null,
        montageEnd: null,
        createWindowDecorativeRequestDtos: [],
      });
    },
    addDecorative(index, floorIndex, roomIndex, windowIndex) {
      const window = this.createHouseItemRequestDtos[index]
        .createFloorRequestDtos[floorIndex]
        .createRoomRequestDtos[roomIndex]
        .createWindowRequestDtos[windowIndex];

      if (window.createWindowDecorativeRequestDtos.length === 0) {
        window.createWindowDecorativeRequestDtos.push({
          name: window.hingType,
          width: 0,
          height: 0,
          sewingType: null,
          orderOtherService: {
            pattern: true,
            sewing: true,
            transport: true,
            montage: true,
          },
          montageValue: false,
          editable: false,
          materialVariantInStoreDtos: [
            {
              materialVariantId: null,
              name: null,
              measurementType: 'Meter',
              quantity: 0,
              length: 0,
              pricePerMeter: 0,
              pricePerMeterWithDiscount: 0,
            },
          ],
        });
      } else {
        window.createWindowDecorativeRequestDtos.forEach((decorative) => {
          decorative.name = window.hingType;
          decorative.width = 0;
          decorative.height = 0;
          decorative.sewingType.value = null;
          decorative.orderOtherService = {
            pattern: true,
            sewing: true,
            transport: true,
            montage: true,
          };
          decorative.montageValue = false;
          decorative.editable = false;
          decorative.materialVariantInStoreDtos.forEach((materialVariant) => {
            materialVariant.materialVariantId = null;
            materialVariant.name = null;
            materialVariant.measurementType = 'Meter';
            materialVariant.quantity = 0;
            materialVariant.length = 0;
            materialVariant.pricePerMeter = 0;
            materialVariant.pricePerMeterWithDiscount = 0;
          });
        });
      }
    },
    addMaterial(index, floorIndex, roomIndex, windowIndex) {
      this.createHouseItemRequestDtos[index].createFloorRequestDtos[floorIndex].createRoomRequestDtos[roomIndex].createWindowRequestDtos[windowIndex].materialVariantInStoreDtos.push({
        materialVariantId: null,
        name: null,
        measurementType: 'Meter',
        quantity: 0,
        length: 0,
        pricePerMeter: 0,
        pricePerMeterWithDiscount: 0,
      });
    },
    addMaterialDecorative(index, floorIndex, roomIndex, windowIndex, windowDecorativeIndex) {
      this.createHouseItemRequestDtos[index].createFloorRequestDtos[floorIndex].createRoomRequestDtos[roomIndex].createWindowRequestDtos[windowIndex].createWindowDecorativeRequestDtos[windowDecorativeIndex].materialVariantInStoreDtos.push({
        materialVariantId: null,
        name: null,
        measurementType: 'Meter',
        quantity: 0,
        length: 0,
        pricePerMeter: 0,
        pricePerMeterWithDiscount: 0,
      });
    },
    removeHouse(index) {
      this.createHouseItemRequestDtos.splice(index, 1);
      this.reassignHouseNames();
    },
    removeFloor(index, floorIndex) {
      this.createHouseItemRequestDtos[index].createFloorRequestDtos.splice(floorIndex, 1);
      this.reassignFloorNames(index);
    },
    removeRoom(index, floorIndex, roomIndex) {
      this.createHouseItemRequestDtos[index].createFloorRequestDtos[floorIndex].createRoomRequestDtos.splice(roomIndex, 1);
      this.reassignRoomNames(index, floorIndex);
    },
    removeWindow(index, floorIndex, roomIndex, windowIndex) {
      this.createHouseItemRequestDtos[index].createFloorRequestDtos[floorIndex].createRoomRequestDtos[roomIndex].createWindowRequestDtos.splice(windowIndex, 1);
      this.reassignWindowNames(index, floorIndex, roomIndex);
    },
    reassignHouseNames() {
      this.createHouseItemRequestDtos.forEach((house, houseIndex) => {
        house.name = `Shtepia nr ${houseIndex + 1}`;
        this.reassignFloorNames(houseIndex);
      });
    },
    reassignFloorNames(index) {
      this.createHouseItemRequestDtos[index].createFloorRequestDtos.forEach((floor, floorIndex) => {
        floor.name = `Kati nr ${floorIndex + 1}`;
        this.reassignRoomNames(index, floorIndex);
      });
    },
    reassignRoomNames(index, floorIndex) {
      this.createHouseItemRequestDtos[index].createFloorRequestDtos[floorIndex].createRoomRequestDtos.forEach((room, roomIndex) => {
        room.name = `Dhoma nr ${roomIndex + 1}`;
      });
    },
    reassignWindowNames(index, floorIndex, roomIndex) {
      this.createHouseItemRequestDtos[index].createFloorRequestDtos[floorIndex].createRoomRequestDtos[roomIndex].createWindowRequestDtos.forEach((window, windowIndex) => {
        window.name = `Objekti nr ${windowIndex + 1}`;
      });
    },
    removeMaterialItemFromCD(index, floorIndex, roomIndex, windowIndex, index2) {
      this.createHouseItemRequestDtos[index].createFloorRequestDtos[floorIndex].createRoomRequestDtos[roomIndex].createWindowRequestDtos[windowIndex].materialVariantInStoreDtos.splice(index2, 1);
    },
    removeDecorativeMaterialItemFromCD(index, floorIndex, roomIndex, windowIndex, windowDecorativeIndex, index2) {
      this.createHouseItemRequestDtos[index].createFloorRequestDtos[floorIndex].createRoomRequestDtos[roomIndex].createWindowRequestDtos[windowIndex].createWindowDecorativeRequestDtos[windowDecorativeIndex].materialVariantInStoreDtos.splice(index2, 1);
    },
    showMaterialName() {
      this.toggleSearch = 'MaterialName'
    },
    showDescription() {
      this.toggleSearch = 'Description'
    },
    async getAllImages(item) {
      this.$store.commit('SET_IMAGES_PRODUCTION_MANAGEMENT', [{
        fileDataId: 'aaf153a4-be48-ee11-abf3-124abb807f69',
        mainDressColorId: 'e2b146ea-a7e9-4584-ee19-08dba4a55447',
        url: '',
        fileName: '1693569414176-createordermodal.png',
      }]);
      try {
        // this.changeLoadingtoTrue(false)

        await this.getImagesByMaterialVariantId(item).then((res) => {
          console.log(res);
          // this.changeLoadingtoTrue(true)
        })
      } catch (e) {
        console.log(e);
        // this.changeLoadingtoTrue(false)
      }
      this.image.imgs = []
      for (let i = 0; i < this.getImagesProduct.length; i++) {
        const image = this.getImagesProduct[i]
        this.image.imgs.push({
          src: image.url,
          title: image.fileName,
          materialVariantPhotoId: image.materialVariantPhotoId,
          fileName: image.fileName,
          fileType: image.fileType,
        })
      }
      this.show()
    },
    show() {
      this.image.visible = true
    },
    handleHide() {
      this.image.visible = false
      this.image.imgs.length = []
    },
    currentStock(value) {
      this.clickedMaterialVariantId = value
      this.currentStockByVariant(value)
    },
    totalPriceCalc(pricePerMeter, length) {
      return Math.ceil(pricePerMeter * length);
    },
    resetMeter(value) {
      value.name = '';
      value.quantity = 0
      value.length = 0
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Meter',
      })
    },
    resetPart(value) {
      value.name = '';
      value.quantity = 0
      value.length = 0
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Part',
      })
    },
    resetPackage(value) {
      value.name = '';
      value.quantity = 0
      value.length = 0
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Package',
      })
    },
    resetSet(value) {
      value.name = '';
      value.quantity = 0
      value.length = 0
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Set',
      })
    },
    resetPair(value) {
      value.name = '';
      value.quantity = 0
      value.length = 0
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Pair',
      })
    },
    resetVerse(value) {
      value.name = '';
      value.quantity = 0
      value.length = 0
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Verse',
      })
    },
    async getMVbyMeter(page, pageS) {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Meter',
      });
    },
    async getMVbyPackage(page, pageS) {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Package',
      });
    },
    async getMVbySet(page, pageS) {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Set',
      });
    },
    async getMVbyVerse(page, pageS) {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Verse',
      });
    },
    async getMVbyPair(page, pageS) {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Pair',
      });
    },
    async getMVbyPart(page, pageS) {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Part',
      });
    },
    nextMeter() {
      if (this.pageM < this.getTotalPagesForMVM && this.selectedMaterialVariant == '') {
        this.pageM += 1;
        this.getMVbyMeter(this.pageM, this.pageSize);
      } else if (this.pageM < this.getTotalPagesForMVM && this.selectedMaterialVariant != '') {
        this.pageM += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariant,
          measurementType: 'Meter',
          pageNumber: this.pageM,
          pageSize: 15,
        });
      }
    },
    prevMeter() {
      if (this.pageM > 1 && this.selectedMaterialVariant == '') {
        this.pageM -= 1;
        this.getMVbyMeter(this.pageM, this.pageSize);
      } else if (this.pageM > 1 && this.selectedMaterialVariant != '') {
        this.pageM -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariant,
          measurementType: 'Meter',
          pageNumber: this.pageM,
          pageSize: 15,
        });
      }
    },
    nextPackage() {
      if (this.pagePackage < this.getTotalPagesForMVM && this.selectedMaterialVariantPackage == '') {
        this.pagePackage += 1;
        this.getMVbyPackage(this.pagePackage, this.pageSize);
      } else if (this.pagePackage < this.getTotalPagesForMVM && this.selectedMaterialVariantPackage != '') {
        this.pagePackage += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantPackage,
          measurementType: 'Package',
          pageNumber: this.pagePackage,
          pageSize: 15,
        });
      }
    },
    prevPackage() {
      if (this.pagePackage > 1 && this.selectedMaterialVariantPackage == '') {
        this.pagePackage -= 1;
        this.getMVbyPackage(this.pagePackage, this.pageSize);
      } else if (this.pagePackage > 1 && this.selectedMaterialVariantPackage != '') {
        this.pagePackage -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantPackage,
          measurementType: 'Package',
          pageNumber: this.pagePackage,
          pageSize: 15,
        });
      }
    },
    nextSet() {
      if (this.pageSet < this.getTotalPagesForMVM && this.selectedMaterialVariantSet == '') {
        this.pageSet += 1;
        this.getMVbySet(this.pageSet, this.pageSize);
      } else if (this.pageSet < this.getTotalPagesForMVM && this.selectedMaterialVariantSet != '') {
        this.pageSet += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantSet,
          measurementType: 'Set',
          pageNumber: this.pageSet,
          pageSize: 15,
        });
      }
    },
    prevSet() {
      if (this.pageSet > 1 && this.selectedMaterialVariantSet == '') {
        this.pageSet -= 1;
        this.getMVbySet(this.pageSet, this.pageSize);
      } else if (this.pageSet > 1 && this.selectedMaterialVariantSet != '') {
        this.pageSet -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantSet,
          measurementType: 'Set',
          pageNumber: this.pageSet,
          pageSize: 15,
        });
      }
    },
    nextVerse() {
      if (this.pageVerse < this.getTotalPagesForMVM && this.selectedMaterialVariantVerse == '') {
        this.pageVerse += 1;
        this.getMVbyVerse(this.pageVerse, this.pageSize);
      } else if (this.pageVerse < this.getTotalPagesForMVM && this.selectedMaterialVariantVerse != '') {
        this.pageVerse += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantVerse,
          measurementType: 'Verse',
          pageNumber: this.pageVerse,
          pageSize: 15,
        });
      }
    },
    prevVerse() {
      if (this.pageVerse > 1 && this.selectedMaterialVariantVerse == '') {
        this.pageVerse -= 1;
        this.getMVbyVerse(this.pageVerse, this.pageSize);
      } else if (this.pageVerse > 1 && this.selectedMaterialVariantVerse != '') {
        this.pageVerse -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantVerse,
          measurementType: 'Verse',
          pageNumber: this.pageVerse,
          pageSize: 15,
        });
      }
    },
    nextPair() {
      if (this.pagePair < this.getTotalPagesForMVM && this.selectedMaterialVariantPair == '') {
        this.pagePair += 1;
        this.getMVbyPair(this.pagePair, this.pageSize);
      } else if (this.pagePair < this.getTotalPagesForMVM && this.selectedMaterialVariantPair != '') {
        this.pagePair += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantPair,
          measurementType: 'Pair',
          pageNumber: this.pagePair,
          pageSize: 15,
        });
      }
    },
    prevPair() {
      if (this.pagePair > 1 && this.selectedMaterialVariantPair == '') {
        this.pagePair -= 1;
        this.getMVbyPair(this.pagePair, this.pageSize);
      } else if (this.pagePair > 1 && this.selectedMaterialVariantPair != '') {
        this.pagePair -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantPair,
          measurementType: 'Pair',
          pageNumber: this.pagePair,
          pageSize: 15,
        });
      }
    },
    nextPart() {
      if (this.pageP < this.getTotalPagesForMVP && this.selectedMaterialVariantPart == '') {
        this.pageP += 1;
        this.getMVbyPart(this.pageP, this.pageSize);
      } else if (this.pageP < this.getTotalPagesForMVP && this.selectedMaterialVariantPart != '') {
        this.pageP += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantPart,
          measurementType: 'Part',
          pageNumber: this.pageP,
          pageSize: 15,
        });
      }
    },
    prevPart() {
      if (this.pageP > 1 && this.selectedMaterialVariantPart == '') {
        this.pageP -= 1;
        this.getMVbyPart(this.pageP, this.pageSize);
      } else if (this.pageP > 1 && this.selectedMaterialVariantPart == '') {
        this.pageP -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantPart,
          measurementType: 'Part',
          pageNumber: this.pageP,
          pageSize: 15,
        });
      }
    },
    async onSearchM(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyMeter(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariant = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariant = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Meter',
            pageNumber: this.pageM,
            pageSize: 15,
          });
        }
      }, 500);
    },
    async onSearchMDesc(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyMeter(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariant = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariant = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Meter',
            pageNumber: this.pageM,
            pageSize: 15,
          })
        }
      }, 500);
    },
    async onSearchPack(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyPackage(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantPackage = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariantPackage = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Package',
            pageNumber: this.pagePack,
            pageSize: 15,
          });
        }
      }, 500);
    },
    async onSearchPackDesc(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyPackage(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantPackage = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariantPackage = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Package',
            pageNumber: this.pagePack,
            pageSize: 15,
          })
        }
      }, 500);
    },
    async onSearchSet(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVBySet(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantSet = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariantSet = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Set',
            pageNumber: this.pageSet,
            pageSize: 15,
          });
        }
      }, 500);
    },
    async onSearchSetDesc(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVBySet(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantSet = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariantSet = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Set',
            pageNumber: this.pageSet,
            pageSize: 15,
          })
        }
      }, 500);
    },
    async onSearchPair(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVByPair(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantPair = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariantPair = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Pair',
            pageNumber: this.pagePair,
            pageSize: 15,
          });
        }
      }, 500);
    },
    async onSearchPairDesc(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVByPair(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantPair = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariantPair = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Pair',
            pageNumber: this.pagePair,
            pageSize: 15,
          })
        }
      }, 500);
    },
    async onSearchVerse(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVByVerse(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantVerse = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariantVerse = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Verse',
            pageNumber: this.pageVerse,
            pageSize: 15,
          });
        }
      }, 500);
    },

    async onSearchVerseDesc(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVByVerse(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantVerse = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariantVerse = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Verse',
            pageNumber: this.pageVerse,
            pageSize: 15,
          })
        }
      }, 500);
    },

    async onSearchP(query) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyPart(this.getCurrentPageForMVP, this.pageSize);
          this.selectedMaterialVariantPart = ''
          this.pageP = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariantPart = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Part',
            pageNumber: this.pageP,
            pageSize: 15,
          });
        }
      }, 500);
    },

    async onSearchPDesc(query) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyPart(this.getCurrentPageForMVP, this.pageSize);
          this.selectedMaterialVariantPart = ''
          this.pageP = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariantPart = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Part',
            pageNumber: this.pageP,
            pageSize: 15,
          })
        }
      }, 500);
    },
  },
};
</script>

<style>
.active2 {
  font-weight: bold;
  color: #242F6E;
  }
.custom-modal__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 10px 0px;
}
.custom-divs1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 3px solid lightgray;
  border-radius: 10px;
  padding: 10px 15px;
  margin-top: 10px;
}
.custom-divs2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 3px solid lightgray;
  border-radius: 10px;
  padding: 10px 15px;
  margin-top: 10px;
}
.custom-divs3 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 3px solid lightgray;
  border-radius: 10px;
  padding: 10px 15px;
  margin-top: 10px;
}
.customDiv {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 15px;
  margin-bottom: 10px;
}
.modal-card__wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    -moz-column-gap: 20px;
    column-gap: 20px;
}
.modal-card__wrapper1 {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    -moz-column-gap: 20px;
    column-gap: 20px;
}
</style>
